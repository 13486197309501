<template>
  <div>
    <div class="nav">订购服务/版本升级</div>
    <div class="newProject">
      <div class="left">
        <div class="title">当前项目信息</div>
        <el-row>
          <el-col :span="3" class="nav_title">
            项目名称
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$store.state.current_project.organization.name}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            当前版本
          </el-col>
          <el-col :span="16" class="nav_content">
            {{service_level}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            产品服务版本
          </el-col>
          <el-col :span="10" class="nav_content">
            <el-radio-group v-model="radio">
              <el-radio label="renew" v-if="this.$store.state.current_project.organization.service_level==0||this.$store.state.current_project.organization.service_level==1"><span style="font-size:18px">基础版</span></el-radio>
              <el-radio label="upgrade" style="font-size:18px" v-if="this.$store.state.current_project.organization.service_level==0||this.$store.state.current_project.organization.service_level==2"><span style="font-size:18px">高级版</span></el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            到期时间
          </el-col>
          <el-col :span="16" class="nav_content">
            {{ (this.$store.state.current_project.organization.service_expire*1000) | dateformat("YYYY-MM-DD HH:mm")}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            开通项目
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$store.state.current_project.organization.name}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            联系电话
          </el-col>
          <el-col :span="6" class="nav_content">
            <el-input v-model="order.phone" placeholder="请输入联系电话"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            企业全称
          </el-col>
          <el-col :span="6" class="nav_content">
            <el-input v-model="order.full_name" placeholder="请输入企业全称"></el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="3" class="nav_title">
            备注
          </el-col>
          <el-col :span="6" class="nav_content">
            <el-input type="textarea" rows='3' placeholder="请输入备注" v-model="order.remark" maxlength="30" show-word-limit>
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top:50px">
          <el-col :span="3" class="nav_title">
            购买方案
          </el-col>
          <el-col :span="16" class="nav_title">
            <price :priceArr="priceArr" ref="pay" @menu="concact">
            </price>
          </el-col>
        </el-row>
        <el-row style="margin-top:50px">
          <el-col :offset="16" :span="10" class="nav_title">

            <el-checkbox size="medium" v-model="agree"></el-checkbox> <span style="font-size:14px;line-height:20px;font-weight:400;margin-right:10px">同意</span>
            <el-link style="font-size:14px;line-height:20px;margin-top:-4px;margin-right:10px" type="primary" href="http://obs.i-patrol.cn/patrol_paid_service_agreement.html" target="_blank">
              《 巡查蝠付费版服务协议 》
            </el-link>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="20">
            <el-button :type="agree?'primary':'info'" :disabled="!agree" round size="mini" @click="post">提交</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { order } from '@/utils/api';
import { isTelAvailable } from '@/utils/verification';
import qs from 'qs';
// import pay from '@/components/pay.vue';
import price from '@/components/price.vue';
export default {
  name:"upgrade",
  data() {
    return {
      radio: 'renew',
      upgrade: [],
      renew: [],
      priceArr: [],
      agree: false,
      order: {
        product_id: '',
        remark: '',
        phone: '',
        full_name: ''
      },
      product: ''
    };
  },
  components: { price },
  computed: {
    user_info() {
      return this.$store.state.user_info;
    },
    service_level() {
      let arr = ['免费版', '基础版', '高级版', '定制版'];
      return arr[this.$store.state.current_project.organization.service_level];
    }
  },
  methods: {
    async getPriceList(cate) {
      const res = await this.$http.get(order.listProduct + '?scene=' + 'renew');
      this.renew = res.data.filter(item => {
        item.discount = ((item.price / item.original_price) * 10).toFixed(1);
        if (item.discount == 10) {
          item.discount = null;
        }
        return item.service_level == 1;
      });
      this.upgrade = res.data.filter(item => {
        item.discount = ((item.price / item.original_price) * 10).toFixed(1);
        if (item.discount == 10) {
          item.discount = null;
        }
        return item.service_level == 2;
      });
      this.priceArr = this[cate];
    },
    concact(e) {
      this.product = e;
      this.order.product_id = e.id;
    },
    async post() {
      if (!this.order.product_id) {
        return this.$message.error('请选择所需产品');
      }
      if (!this.order.phone) {
        return this.$message.error('请填写联系电话');
      }
      if (!this.order.full_name) {
        return this.$message.error('请填写企业全称');
      }
      if (!this.order.remark) {
        return this.$message.error('请填写联系备注');
      }
      if (!isTelAvailable(this.order.phone)) {
        return this.$message.error('请输入正确的电话号码');
      }
      const res = await this.$http.post(order.renew, this.order);
      if (res.code == 0) {
        this.order.id=res.data.id
        this.order.numbers=res.data.number.number
        this.order.name = this.$store.state.current_project.organization.name;
        let dataArr = [...this.upgrade, ...this.renew];
        this.order.projectName = dataArr.find(
          item => item.id == this.order.product_id
        ).name;
        this.order.price = dataArr.find(
          item => item.id == this.order.product_id
        ).price;
        this.order.recipient={
          address:''
        }
        this.$router.push({
          name: 'pay',
          params: {
            info: this.order
          }
        });
      } else {
        this.$message.error('将三秒后跳转前往订单列表页面');
        setTimeout(() => {
          this.$router.push('orderlist');
        }, 3000);
      }
    },
    async getbankAccount(){
      const res = await this.$http.get('system/bankAccount')
      console.log(res)
    }
  },
  created() {
    if (this.$store.state.current_project.organization.service_level == 0||this.$store.state.current_project.organization.service_level == 1) {
      this.radio = 'renew';
    }else if(this.$store.state.current_project.organization.service_level == 2){
      this.radio = 'upgrade';
    }
    this.getPriceList(this.radio);
  },
  mounted() {},
  watch: {
    radio: {
      handler(value) {
        this.priceArr = this[value];
        this.$refs.pay.id = -1;
        // this.product = null;
        this.order.product_id = '';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.newProject {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 1200px;
  height: 900px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  border: 1px solid #e6ecf5;
  margin: 20px auto;
  border-radius: 5px;
  .left {
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
    .title {
      font-size: $fontSizeMap-20;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
.nav {
  background-color: #fff;
  width: 1200px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  color: #666666;
  padding-left: 30px;
}
.el-table--mini /deep/ td,
.el-table--mini /deep/th {
  padding: 0;
}
.el-table /deep/.cell {
  // padding: 0;
  // height: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.el-table /deep/ td {
  width: 100%;
}
.nav_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 50px;
}
.nav_content {
  font-size: 16px;
  line-height: 50px;
}
</style>
