import { render, staticRenderFns } from "./upgrade.vue?vue&type=template&id=61acf89a&scoped=true&"
import script from "./upgrade.vue?vue&type=script&lang=js&"
export * from "./upgrade.vue?vue&type=script&lang=js&"
import style0 from "./upgrade.vue?vue&type=style&index=0&id=61acf89a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61acf89a",
  null
  
)

export default component.exports